<!--
 * @Author: gaojingran
 * @Date: 2021-04-19 16:20:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-15 17:25:11
 * @Description: 新增报价选项
-->
<style lang="less" scoped>
.not-found-price {
  text-align: center;
  color: @text_color_2;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  &:hover {
    text-decoration: underline;
    color: @primary-color;
  }
}
</style>
<template>
  <a-modal
    :width="580"
    :title="$t('orderInfo.btn_quote_changeOrderType')"
    :maskClosable="false"
    :visible="visible"
    @cancel="handleCancel"
    @ok="handleSubmit"
  >
    <a-spin :spinning="loading">
      <a-form-model ref="form" v-bind="formLayout" :colon="true" :model="form" :rules="rules">
        <a-form-model-item prop="serviceType" :label="$t('orderInfo.quote_option_type')">
          <a-select
            allowClear
            v-model="form.serviceType"
            @change="() => ((form.filesResponse = null), (wordAmountList = []))"
            :placeholder="
              $t('placeholder.select', {
                attr: $t('orderInfo.quote_option_type'),
              })
            "
          >
            <a-select-option v-for="item in serviceList" :key="item.serviceId" :value="item.serviceId">
              {{ item.serviceLabel }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item :label="$t('orderInfo.upload_num_file')" required v-if="form.serviceType != 'default'">
          <template>
            <a-upload
              accept=".xlsx"
              :beforeUpload="handleBeforeUpload"
              :action="uploadAction"
              :showUploadList="false"
              :multiple="false"
              :disabled="loading || uploading"
              :withCredentials="true"
              :headers="uploadHeaders"
              @change="handleUploadChange"
            >
              <a-button type="primary" :loading="uploading">
                <a-icon type="upload" v-if="!uploading" />{{ $t('uploadButton.title') }}
              </a-button>
            </a-upload>
            <a-button type="link" @click="handleDownloadTemplate">
              {{ $t('orderInfo.download_template') }} <a-icon type="download" />
            </a-button>
          </template>
        </a-form-model-item>
        <a-form-model-item
          prop=""
          :label="$t('orderInfo.num_read')"
          v-if="wordAmountList && wordAmountList.length && form.serviceType != 'default'"
        >
          <a-row>
            <a-col :span="8" v-for="(item, index) in wordAmountList" :key="index">
              <li>{{ item.targetCode }}-{{ item.wordAmount }}</li>
            </a-col>
          </a-row>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>

<script>
import { uuid } from '@/utils/utils'
import Cookies from 'js-cookie'
import { downloadUrl } from '@/utils/utils'

const { validate } = window.$g

export default {
  name: 'ChangeOrderType',
  props: {
    // 订单详情
    orderInfo: {
      type: Object,
      default: () => ({}),
    },
    // 报价单详情
    info: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      priceList: [],
      serviceList: [],
      formLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
      form: {
        serviceType: undefined,
        filesResponse: null,
      },
      rules: {
        serviceType: [validate.required],
      },
      uploadHeaders: {
        token: Cookies.get(window.$g.storageKey.TOKEN) || '',
      },
      loading: false,
      uploading: false,

      wordAmountList: [],
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getOtherPrice()
      } else {
        this.form = {
          serviceType: undefined,
          filesResponse: null,
        }
        this.wordAmountList = []
        this.$refs.form.resetFields()
      }
    },
  },
  computed: {
    uploadAction() {
      return (
        window.SITE_CONFIG['apiURL'] +
        window.$g.api['kingsoftOrderparseWordAmountUpload_no_method'] +
        `?orderId=${this.info.id}`
      )
    },
    // 根据服务类型find价格列表
    cusPriceList() {
      return this.priceList.find((v) => v.serviceId === this.form.serviceType)?.supPriceList || []
    },
  },
  methods: {
    // 其他服务类型价格
    async getOtherPrice() {
      try {
        const price = await this.$http({
          key: 'orderProcessOtherPrice',
          params: {
            id: this.info.id,
          },
        })
        this.priceList = price
        this.serviceList = [
          ...price,
          {
            serviceLabel: '默认',
            serviceId: 'default',
            serviceKind: 'default',
          },
        ]
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    // 下载模板
    handleDownloadTemplate() {
      let url =
        'https://genplus-common.oss-cn-shanghai.aliyuncs.com/excel_template/gient_cloud/kingsoft/%E6%8A%A5%E4%BB%B7%E5%AD%97%E6%95%B0%E4%B8%8A%E4%BC%A0%E6%A8%A1%E6%9D%BF-V1.0.xlsx'
      downloadUrl(url)
    },
    // 上传
    handleBeforeUpload(file) {
      const { name } = file
      const suffix = name.slice(name.lastIndexOf('.'))
      const sizeMB = (file.size / (1024 * 1024)).toFixed(2)

      if (suffix !== '.xlsx') {
        this.$message.error(this.$t('prompt.upload_illegal_format', { name }))
        return false
      }
      if (sizeMB > 5) {
        this.$message.error(this.$t('orderInfo.MB_less_5'))
        return false
      }
      this.uploading = true
    },
    // 模板上传
    handleUploadChange({ file }) {
      if (file.status === 'done') {
        this.uploading = false
        if (file.response.code !== 0) {
          this.form.filesResponse = null
          return this.$message.error(file.response.msg)
        } else {
          this.form.filesResponse = file.response.data
          this.wordAmountList = file.response.data.wordAmountList || []
        }
      }
    },

    handleCancel() {
      if (this.loading) return
      this.$emit('update:visible', false)
    },
    handleSubmit() {
      if (this.loading) return
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          let res = []
          if (this.form.serviceType != 'default') {
            if (!this.form.filesResponse) {
              this.loading = false
              this.$message.error(this.$t('validate.upload_num_file'))
              return false
            }

            this.wordAmountList.forEach((item) => {
              const { serviceKind, serviceLabel } = this.serviceList.find((v) => v.serviceId === this.form.serviceType)
              const { price, sourceCode, unit } = this.cusPriceList.find(
                (v) => v.targetCode == item.targetCode || !v.targetCode
              ) || {
                price: null,
                sourceCode: null,
                unit: null,
              }
              const selectivePriceList =
                this.cusPriceList.filter((v) => v.targetCode == item.targetCode || !v.targetCode) || []
              res.push({
                bacth: true, // 用于判断是不是从更改服务类型中新加的
                no_operate: true, // 用于判断是不是存在操作
                id: uuid(),
                new: true, // 用于判断是不是新加的
                serviceType: this.form.serviceType,
                serviceLabel,
                serviceKind,
                price: selectivePriceList.length == 1 ? price : null,
                priceType: selectivePriceList.length == 1 ? price : null,
                amount: Number(item.wordAmount),
                wordAmount: Number(item.wordAmount),
                sourceCode: sourceCode || this.info.sourceCode,
                targetCode: item.targetCode,
                unit,
                selectivePriceList,
              })
            })
          }
          this.$emit('addItem', res)
          this.$emit('update:visible', false)
        }
        this.loading = false
      })
    },
  },
}
</script>
